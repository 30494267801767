import { checkExhausted } from "@qmspringboard/shared/dist/utils";
import React, { ReactElement, useMemo } from "react";
import styled from "@emotion/styled";
import type * as Requirements from "../model/types";
import { Heading, Info } from "../ui";

interface IProps {
  requirements: Requirements.Requirements;
}

const Ul = styled.ul`
  list-style: disc;
`;

const Li = styled.li`
  margin-left: 2rem;
`;

interface Item {
  type: "Req";
  text: string;
}

interface OneOf {
  type: "OneOf";
  children: Req[];
}

interface SomeOf {
  type: "SomeOf";
  num: number;
  children: Req[];
}

interface AllOf {
  type: "AllOf";
  children: Req[];
}

type Req = OneOf | SomeOf | AllOf | Item;

function item(text: string): Req {
  return { type: "Req", text };
}

function oneOf(children: Req[]): Req {
  return { type: "OneOf", children };
}

function someOf(num: number, children: Req[]): Req {
  return num === 1 ? { type: "OneOf", children } : num === children.length ? { type: "AllOf", children } : { type: "SomeOf", num, children };
}

function allOf(children: Req[]): Req {
  return { type: "AllOf", children };
}

function createReqs(requirements: Requirements.Requirements) {
  return allOf([
    oneOf(
      requirements.alternatives.map(({ overall, conditions }) =>
        allOf([
          oneOf([
            ...(overall.a2Grades.length > 0 ? [item(`${overall.a2Grades.join("")} at A-Level`)] : []),
            ...(overall.ibPoints > 0 || overall.ibGrades.length > 0
              ? [
                  item(
                    "International Baccalaureate with " +
                      [
                        ...(overall.ibPoints > 0 ? [`${overall.ibPoints} points`] : []),
                        ...(overall.ibGrades.length > 0 ? [`${overall.ibGrades.join("")} at Higher Level`] : []),
                      ].join(" and "),
                  ),
                ]
              : []),
            ...(overall.btecGrades != null && overall.btecGrades.length > 0 ? [item(`${overall.btecGrades.join("")} at BTEC (modified 2019)}`)] : []),
          ]),
          ...conditions.map(cond =>
            someOf(
              cond.numberRequired,
              cond.subjects.map(subj =>
                subj.type === "RequiredSubjectNoGrade"
                  ? item(subj.subject)
                  : item(`${subj.a2Grade} at A-Level or ${subj.ibGrade} at IB Higher Level in ${subj.subject}`),
              ),
            ),
          ),
        ]),
      ),
    ),
    ...(requirements.english ? [item("IELTS or GCSE/IB English C/4")] : []),
    ...(requirements.maths ? [item("GCSE/IB Mathematics B/5")] : []),
  ]);
}

function flattenReqs(req: Req): Req {
  switch (req.type) {
    case "Req":
      return req;

    case "OneOf": {
      const children = req.children.map(flattenReqs);

      return children.length === 1
        ? children[0]
        : {
            ...req,
            children: children.flatMap(child => (child.type === "OneOf" ? child.children : [child])),
          };
    }

    case "AllOf": {
      const children = req.children.map(flattenReqs);
      return children.length === 1
        ? children[0]
        : {
            ...req,
            children: children.flatMap(child => (child.type === "AllOf" ? child.children : [child])),
          };
    }

    case "SomeOf": {
      const children = req.children.map(flattenReqs);
      return { ...req, children };
    }

    default:
      return checkExhausted(req);
  }
}

function Reqs({ req, root }: { req: Req; root?: boolean }): ReactElement {
  switch (req.type) {
    case "Req":
      return <>{req.text}</>;

    case "OneOf":
    case "SomeOf":
    case "AllOf": {
      return req.children.length === 0 ? (
        <></>
      ) : req.children.length === 1 ? (
        <Reqs req={req.children[0]} />
      ) : (
        <>
          {root && req.type === "AllOf" ? null : (
            <p>{req.type === "AllOf" ? "All of" : req.type === "OneOf" ? "One of" : req.num === 1 ? "One of" : `At least ${req.num} of`}</p>
          )}
          <Ul>
            {req.children.map((item, index) => (
              <Li key={index}>
                <Reqs req={item} />
              </Li>
            ))}
          </Ul>
        </>
      );
    }

    default:
      return checkExhausted(req);
  }
}

export default function ProgrammeQualificationGuidance({ requirements }: IProps) {
  const req = useMemo(() => flattenReqs(createReqs(requirements)), [requirements]);

  return (
    <Info>
      <Heading variant="heading2">Entry Requirements</Heading>
      <p>This programme has the following entry requirements:</p>
      <Reqs req={req} root={true} />
    </Info>
  );
}
